import https from "axios";

class TutorialDataService {
  getAll() {
    return https.get("products");
  }

  get(id) {
    return https.get(`products/${id}`);
  }

  create(data) {
    return https.post("addproducto", data);
  }

  update(id, data) {
    return https.put(`updateproducto/${id}`, data);
  }

  delete(id) {
    return https.delete(`deleteproducto/${id}`);
  }

  deleteAll() {
    return https.delete(`products`);
  }

  findByTitle(sku) {
    return https.get(`/products?sku=${sku}`);
  }
}

export default new TutorialDataService();


import Vue from 'vue'

import Router from 'vue-router'

import MyHome from './components/MyHome.vue'

import MyNewLogin from './components/MyNewLogin.vue'

import MyRegister from './components/MyRegister.vue'

import ConsumoApi from './components/ConsumoApi.vue'

import TutorialsList from './components/TutorialsList.vue'

import TutorialEdit from './components/TutorialEdit.vue'

import AddTutorial from './components/AddTutorial.vue'

import MisPruebas from './components/MisPruebas.vue'

import MisOrdenes from './components/MisOrdenes.vue'

import ActualizarWS from './components/ActualizarWS.vue'

import MiCRUD from './components/MiCRUD.vue'

import EditarCRUD from './components/EditarCRUD.vue'

import MisWaybills from './components/MisWaybills.vue'

import MisVentas from './components/MisVentas.vue'



Vue.use(Router)

export default new Router({
    mode: 'history',
    routes: [
        {path: '/MyHome', component: MyHome},
        {path: '/', component: MyHome},
        {path: '/MyNewLogin', component: MyNewLogin},
        {path: '/MyRegister', component: MyRegister},
        {path: '/ConsumoApi', component: ConsumoApi},
        {path: '/TutorialsList', component: TutorialsList},
        {path: '/TutorialEdit/:id', component: TutorialEdit},
        {path: '/AddTutorial', component: AddTutorial},
        {path: '/MisPruebas', component: MisPruebas},
        {path: '/MisOrdenes', component: MisOrdenes},
        {path: '/ActualizarWS', component: ActualizarWS},
        {path: '/MiCRUD', component: MiCRUD},
        {path: '/EditarCRUD/{id}', name: 'EditarCRUD', component: EditarCRUD},
        {path: '/MisWaybills', component: MisWaybills},
        {path: '/MisVentas', component: MisVentas}
    ]
})

<template>
<div>
    <h3 v-if="me">Hola {{me.name}}, has iniciado sesión</h3>
    <h3 v-if="!me">No has iniciado sesión</h3>
</div>
    </template>
    
    
    
    
    <script>
    import {mapGetters} from 'vuex';
    export default {
    
        name: 'MyHome',
        computed: {
            ...mapGetters(['me'])
        }
    }
    </script>